import React from "react";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

import confused from "../images/confused-group-scientists.png";
import SEO from "../components/seo";

export default () => (
  <Container maxWidth="md">
    <SEO title="Error 404 (Not Found)!!!" />
    <Typography component="div">
      <Box
        textAlign="center"
        component="h1"
        m={1}
        fontFamily="DM Serif Text"
        fontWeight="700"
        color="#3d3d3d"
        fontSize="h2.fontSize"
      >
        Not Found
      </Box>
      <br />
      <br />
      <img
        alt="Not found"
        src={confused}
        style={{ display: "block", margin: "auto" }}
      />
      <br />
      <br />
      <Box
        color="#3d3d3d"
        component="p"
        textAlign="center"
        fontSize="h6.fontSize"
      >
        The page you want was not found.
        <br />
        Why don&#39;t you try again?
      </Box>
      <Box textAlign="center">
        <Button onClick={() => navigate("/")} color="primary" disableElevation>
          Home
        </Button>
      </Box>
    </Typography>
  </Container>
);
